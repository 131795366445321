import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "micevski_transfers_to_rfs" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Long negotiations and finally done ✅ Darko Micevski, captain of FK Vardar, signed a contract with FC RFS for two years!</p>
        </div>
      </div>
    )
  }
}